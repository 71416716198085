import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import "./dashboard.scss";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  ASSET_REDIRECT_SCOPES,
  ASSET_SUITE_LOGIN_URL,
  ASSET_URLS,
  COOKIE_DETAILS,
  DASHBOARD_TYPE,
  IFRAME_ID,
  KEYCLOAK_CLIENTS,
  SESSION_KEY,
  SESSION_TYPE,
} from "../../config/default.config";
import assetModelsIcon from "../../assets/images/asset_model_icon.svg";
import analyticalsIcon from "../../assets/images/analytical-dashboard.svg";
import gridMonitoringIcon from "../../assets/images/grid-monitoring-icon.svg";
import assetManagementIcon from "../../assets/images/asset_management_icon.svg";
import adminConsoleIcon from "../../assets/images/device_management_icon.svg";
import managementDashboardIcon from "../../assets/images/management_dashboard_icon.svg";
import serverRoomMonitoringDashboard from "../../assets/images/Server-Room-Monitoring-Dashboard-icon.svg";
import operationalDashboardIcon from "../../assets/images/operational_dashboard.svg";
import fsmIcon from "../../assets/images/fsm_icon.svg";
import {
  IUserAuthorizations,
  IUserTokenDetails,
} from "../../store/keycloak/asset-keycloak.interface";
import Navbar from "../../components/navbar.component/navbar";
import { setTokens } from "../../store/keycloak/asset-keycloak.slice";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { encryptUtils } from "../../utils/encryption.utils";
import { cookieUtils } from "../../utils/cookie.utils";
import settingsIcon from "../../assets/images/seqrops-settings_icon.svg";
import seqropsSiteManagement from "../../assets/images/site-management-icon.svg";

import { setTheme } from "../../store/seqr-ops/seqr-ops.slice";

function Dashboard() {
  const authorozedScopes = useAppSelector((state) => state.assetKeycloak);
  const dispatch = useAppDispatch();
  const [loggedUser, setLoggedUser] = useState<string>();
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();
  const [ready, setReady] = useState<boolean>(false);
  const [zoomedTile, setZoomedTile] = useState<string>("");
  const urlParams = new URLSearchParams(window.location.search);

  const handleMouseEnter = (tile: string) => {
    setZoomedTile(tile);
  };

  const handleMouseLeave = () => {
    setZoomedTile("");
  };

  // const handleClick = (e: any) => {
  //   // e.preventDefault();
  //   // dispatch(seqrOpsActions.setLoader(true));
  //   window.location.href = ASSET_URLS.ASSET_MODELS_URL;
  // };

  useEffect(() => {
    let cookieName = COOKIE_DETAILS.COOKIE_NAME;
    let sessionId = cookieUtils.getCookie(cookieName);

    let decryptedSessionThemeType = encryptUtils.decryptURL(sessionId);

    if (
      decryptedSessionThemeType?.sessionId == null ||
      decryptedSessionThemeType?.sessionId === undefined ||
      decryptedSessionThemeType?.sessionId === ""
    ) {
      localStorage.clear();
      window.location.replace(ASSET_SUITE_LOGIN_URL);
    } else {
      if (
        sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !==
        null
      ) {
        let cookieName = COOKIE_DETAILS.COOKIE_NAME;
        let encryptedCookieData = cookieUtils.getCookie(cookieName);
        let decryptedCookieData = encryptUtils.decryptURL(encryptedCookieData);
        if (decryptedCookieData?.themeType === "LIGHT") {
          dispatch(setTheme(false));
        } else if (decryptedCookieData?.themeType === "DARK") {
          dispatch(setTheme(true));
        }

        let userTokenDetails: IUserTokenDetails =
          sessionStorageUtils.getLocalStorage(
            SESSION_KEY.LOCAL_STORAGE_KEY
          )?.token;
        if (userTokenDetails !== null) {
          setUserScopesFromLocalStorage(userTokenDetails?.userAuthorizations);
          setLoggedUser(userTokenDetails?.username);
          dispatch(setTokens(userTokenDetails));

          const newArray = [];
          let data: any = userTokenDetails?.userAuthorizations;
          const trueKeys = Object.keys(data).filter((key) => data[key]);
          trueKeys.forEach((key) => {
            newArray.push(key);
          });
          if (
            trueKeys.length === 5 &&
            trueKeys.includes(ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD) &&
            urlParams.get(DASHBOARD_TYPE.REDIRECT) == null
          ) {
            let dashboardURL = ASSET_URLS.OPERATIONAL_DASHBOARD_URL;
            const queryParams = new URLSearchParams({
              dashboardType: ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD,
            });
            const urlWithParams = `${dashboardURL}?${queryParams.toString()}`;
            window.location.href = urlWithParams;
          }

          setReady(true);
        }
      } else {
        window.location.href = ASSET_SUITE_LOGIN_URL;
      }
    }
  }, []);

  const renderGridMonitoringApp = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "gridMonitoring" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("gridMonitoring")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          window.open(ASSET_URLS.GRID_MONOTORING_APP, "_self");
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section">
          <div className="seqrops-tile-inner-section">
            <img
              src={gridMonitoringIcon}
              className="img-fluid"
              alt="grid-monitoring-app"
            />
            <span>
              Grid <br /> Monitoring
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderAnalyticsDashboard = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "analyticsDashboard" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("analyticsDashboard")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          handleDashboardClick(ASSET_REDIRECT_SCOPES.ANALYTICS_DASHBOARD);
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section1">
          <div className="seqrops-tile-inner-section">
            <img
              src={analyticalsIcon}
              className="img-fluid"
              alt="efficency-dsh-img"
            />
            <span>
              Analytics <br /> Dashboard
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderAssetModels = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "assetModels" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("assetModels")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          window.open(ASSET_URLS.ASSET_MODELS_URL, "_self");
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section">
          <div className="seqrops-tile-inner-section">
            <img
              src={assetModelsIcon}
              className="img-fluid"
              alt="asset-models-img"
            />
            <span>
              Asset <br /> Models
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderAssetManagement = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "assetManagement" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("assetManagement")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          window.open(ASSET_URLS.ASSET_MANAGEMENT_URL, "_self");
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section">
          <div className="seqrops-tile-inner-section">
            <img
              src={assetManagementIcon}
              className="img-fluid"
              alt="asset-management-img"
            />
            <span>
              Asset <br /> Management
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderDeviceManagement = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "deviceManagement" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("deviceManagement")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          window.open(ASSET_URLS.DEVICE_MANAGEMENT_URL, "_self");
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section">
          <div className="seqrops-tile-inner-section">
            <img
              src={adminConsoleIcon}
              className="img-fluid"
              alt="admin-console-img"
            />
            <span>
              Device <br /> Management
            </span>
          </div>
        </div>
      </div>
    );
  };
  const [dashboardTypee, setDashboardTypee] = useState<string | null>(null);

  useEffect(() => {
    let userTokenDetails: IUserTokenDetails =
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY)?.token;
    if (userTokenDetails !== null) {
      setUserScopesFromLocalStorage(userTokenDetails?.userAuthorizations);
      setLoggedUser(userTokenDetails?.username);
      dispatch(setTokens(userTokenDetails));
      const newArray = [];
      let data: any = userTokenDetails?.userAuthorizations;
      const trueKeys = Object.keys(data).filter((key) => data[key]);
      trueKeys.forEach((key) => {
        newArray.push(key);
      });
      const urlParams = new URLSearchParams(window.location.search);
      // const dashboardType = urlParams.get("redirectFrom");
      // setDashboardTypee(dashboardType);
    }
  }, [dashboardTypee]);

  const handleDashboardClick = (domain: string) => {
    let dashboardURL = ASSET_URLS.ANALYTICS_DASHBOARD;

    // const queryParams = new URLSearchParams({ dashboardType: domain });
    // const urlWithParams = `${dashboardURL}?${queryParams.toString()}`;
    window.open(dashboardURL, "_self");
  };

  if (!ready) {
    return null;
  }

  return (
    <>
      {ready && (
        <>
          <div className="seqrops-dashboard-wrapper">
            <div className="seqrops-navbar-section">
              <Navbar
                loggeduser={
                  userScopesFromLocalStorage !== undefined
                    ? loggedUser
                    : authorozedScopes.username
                }
              />
            </div>
            <div className="container mt-5">
              <div className="tile-wrapper-main flex-wrap">
                <React.Fragment>
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.asset_models
                    : authorozedScopes.userAuthorizations.asset_models) &&
                    renderAssetModels()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.asset_management
                    : authorozedScopes.userAuthorizations.asset_management) &&
                    renderAssetManagement()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.grid_monitoring_app
                    : authorozedScopes.userAuthorizations
                        .grid_monitoring_app) && renderGridMonitoringApp()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.analytics_dashboard
                    : authorozedScopes.userAuthorizations
                        .analytics_dashboard) && renderAnalyticsDashboard()}
                  {/* {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.device_management
                    : authorozedScopes.userAuthorizations.device_management) &&
                    renderDeviceManagement()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.field_service_management
                    : authorozedScopes.userAuthorizations
                        .field_service_management) &&
                    renderFieldServiceManagement()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.operational_dashboard
                    : authorozedScopes.userAuthorizations
                        .operational_dashboard) && renderOperationalDashboard()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.management_dashboard
                    : authorozedScopes.userAuthorizations
                        .management_dashboard) && renderManagementDashboard()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.monitoring_dashboard
                    : authorozedScopes.userAuthorizations
                        .monitoring_dashboard) &&
                    renderServiceRoomMonitoringDashboard()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.asset_contract
                    : authorozedScopes.userAuthorizations.asset_contract) &&
                    renderConworx()} */}
                </React.Fragment>

                {/* //ux user case next if */}
                {/* {authorozedScopes.username === "ux user" && 
                    <>
                        {renderAssetManagement() }    
                        {renderAssetModels() }    
                        {renderDeviceManagement() }   
                        {renderFieldServiceManagement() }
                        {renderManagementDashboard() }
                        {renderOperationalDashboard() }
                    </>} */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Dashboard;
