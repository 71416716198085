import {
  COOKIE_DETAILS,
  KEYCLOAK_CLIENTS,
  KEYCLOAK_CLIENT_SECRETS,
  KEYCLOAK_GRANT_TYPES,
  MESSAGES,
  UNAUTHORIZED_LOGIN,
} from "../../config/default.config";
import { interceptiorUtils } from "../../utils/interceptor.utils";
import {
  ILoginCredentials,
  ILogoutTokenPayload,
  ISessionDetails,
  ITokenDetails,
  IValidateTokenPayload,
} from "./asset-keycloak.interface";
import { assetKeycloakService } from "./asset-keycloak.service";
import { TokenServices } from "./token-services";
import { AppThunk } from "..";
import { setTokens } from "./asset-keycloak.slice";
import { toasterUtils } from "../../utils/toaster.utils";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { cookieUtils } from "../../utils/cookie.utils";
import { encryptUtils } from "../../utils/encryption.utils";
import { setLoading } from "../seqr-ops/seqr-ops.slice";
import { seqrOpsActions } from "../seqr-ops/seqr-ops.action";

const getLoginToken = (
  ILoginCredentials: ILoginCredentials,
  setSuccess: (state: boolean) => void
): AppThunk => {
  // const getLoginToken = (ILoginCredentials: ILoginCredentials): AppThunk => {

  return async (dispatch, getState) => {
    const params = new URLSearchParams();
    params.append("username", ILoginCredentials.username);
    params.append("password", ILoginCredentials.password);
    params.append("client_id", KEYCLOAK_CLIENTS.ASSET_SUITE);
    params.append("client_secret", KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE);
    params.append("grant_type", KEYCLOAK_GRANT_TYPES.PASSWORD);
    params.append("refresh_token", "");
    params.append("session_id", "");

    try {
      interceptiorUtils.initialize();
      const response = await assetKeycloakService.getLoginToken(params);
      if (response.status === 200 || response.status === 201) {
        let cookieName = COOKIE_DETAILS.COOKIE_NAME;
        let domainName = COOKIE_DETAILS.COOKIE_DOMAIN;
        let sessionId = response?.data?.session_state;
        console.log("sessionId ::: ", response?.data?.session_state);
        // let cookieData = {
        //   sessionId: sessionId,
        //   themeType: "",  
        // };        
        // let encryptedSessionThemeType= encryptUtils.encryptURL(cookieData)        
        // cookieUtils.setCookie(cookieName, encryptedSessionThemeType, { domain: domainName });
        var token = response?.data?.access_token;
        var refresh_token = response?.data?.refresh_token;

        const ITokenDetails: ITokenDetails = {
          auth_token: token,
          refresh_token: refresh_token,
          client_id: KEYCLOAK_CLIENTS.ASSET_SUITE,
          client_secret: KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE,
          username: ILoginCredentials.username,
          password: ILoginCredentials.password,
          session_id: sessionId,
        };

        //interceptors
        if (
          response?.data?.session_state != null &&
          response?.data?.session_state !== undefined
        ) {
          interceptiorUtils.initialize();
        } else {
        }

        let IValidateTokenPayload: IValidateTokenPayload = {
          token: token,
          username: ILoginCredentials.username,
          password: ILoginCredentials.password,
          client_id: KEYCLOAK_CLIENTS.ASSET_SUITE,
          client_secret: KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE,
          grant_type: KEYCLOAK_GRANT_TYPES.PASSWORD,
        };
        dispatch(setTokens(ITokenDetails));
        await TokenServices.processToken(IValidateTokenPayload, ITokenDetails);
        setSuccess(true);
      } else {
        // console.log("err ::::", response);

        dispatch(seqrOpsActions.setLoader(false));
        toasterUtils.showError(MESSAGES.INVALID_LOGIN_CREDENTIALS);
      }
    } catch (err: any) {
      if (
        err?.response?.statusText === UNAUTHORIZED_LOGIN ||
        err?.response?.status == 401
      ) {
        dispatch(seqrOpsActions.setLoader(false));
        toasterUtils.showError(MESSAGES.INVALID_LOGIN_CREDENTIALS);
      }
    }
    // catch (err) {
    //   return null;
    //   }
  };
};

// export const validateToken = async (IValidateTokenPayload : IValidateTokenPayload, refresh_token: string, ITokenDetails: ITokenDetails) => {

//   // const params = new URLSearchParams();
//   // params.append('token', IValidateTokenPayload.token);
//   // params.append('client_id', KEYCLOAK_CLIENTS.ASSET_SUITE);
//   // params.append('client_secret', KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE);
//   // params.append('username', IValidateTokenPayload.username);
//   // params.append('password', IValidateTokenPayload.password);

//   try {
//     // const response = await assetKeycloakService.validateToken(params);
//     // if (response?.data?.active) {
//       await TokenServices.processToken(IValidateTokenPayload, ITokenDetails);
//     //  return true;
//     // } else {
//       // return null;
//     // }
//   } catch (err) {
//     return null;
//   }
//   };

const logoutToken = async (
  ILogoutTokenPayload: ILogoutTokenPayload,
  setSuccess: (state: boolean) => void
) => {
  const params = new URLSearchParams();

  params.append("refresh_token", ILogoutTokenPayload.refresh_token);
  params.append("session_id", ILogoutTokenPayload.session_id);

  try {
    const response = await assetKeycloakService.logoutToken(params);
    if (response.status) {
      let cookieName = `${process.env.REACT_APP_COOKIE_PREFIX}_user_session_id`;
      cookieUtils.deleteCookie(cookieName);
      toasterUtils.showSuccess(MESSAGES.LOGGED_OUT);
      setSuccess(true);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const assetKeycloakActions = {
  getLoginToken,
  logoutToken,
};
