import { useEffect } from "react";
import LoginForm from "../forms/login.form/login.form";
import { useAppSelector } from "../../store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import seqropsIcon from "../../assets/images/grid-monitoring-logo.svg";
import seqropsLog from "../../assets/images/login_bg.jpg";
import { setLogin } from "../../store/seqr-ops/seqr-ops.slice";
import "./login.scss";

function Login() {
  return (
    <>
      <div className="seqrops-login-wrapper ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-lg-6 position-relative">
              <div className="seqrops-login-logo-section">
                <img
                  src={seqropsIcon}
                  alt="seqrops"
                  className="seqrops-logo-login"
                />
              </div>
              <div className="seqrops-login-form-section">
                <LoginForm />
              </div>
              <div className="powedby">{/* <p>Powered By EquServ</p> */}</div>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-6 p-0 seqropsLogSideBar">
              <img src={seqropsLog} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
