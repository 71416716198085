import "./App.css";
import DialogProvider from "./components/dialogs/DialogProvider";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./components/login.component/login";
import Dashboard from "./modules/dashboard/dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "./store";
import { useEffect, useState } from "react";
import {
  ASSET_REDIRECT_SCOPES,
  ASSET_SUITE_LOGIN_URL,
  CLEAR_CLIENT_SCOPES,
  COOKIE_DETAILS,
  DASHBOARD_TYPE,
  KEYCLOAK_CLIENTS,
  SESSION_KEY,
  SESSION_TYPE,
} from "./config/default.config";
// import { postCrossDomainMessage } from './store/keycloak/token-services';
import { sessionStorageUtils } from "./utils/session-storage.utils";
import { encryptUtils } from "./utils/encryption.utils";
import { cookieUtils } from "./utils/cookie.utils";
import { createContext } from "react";
import ReactSwitch from "react-switch";
import Navbar from "./components/navbar.component/navbar";
import { setTheme } from "./store/seqr-ops/seqr-ops.slice";
interface ThemeContextType {
  theme: string;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextType | null>(null);

function App() {
  const loading = useAppSelector((state) => state.seqrOps.loadingState.loading);
  const theme = useAppSelector((state) => state.seqrOps.theme);
  const navigate = useNavigate();
  const [ready, setReady] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch = useAppDispatch();
  const [localThemeState, setLocalThemeState] = useState<string>("");

  useEffect(() => {
    let appsuiteTokenData: any = sessionStorageUtils.getLocalStorage(
      SESSION_KEY.LOCAL_STORAGE_KEY
    );
    let tokenData: any = localStorage.getItem(SESSION_KEY.LOCAL_STORAGE_KEY);
    if (
      tokenData !== null ||
      (appsuiteTokenData != null &&
        urlParams.get(DASHBOARD_TYPE.REDIRECT) == null)
    ) {
      setReady(true);
      navigate("/dashboard");
    } else if (
      tokenData !== null ||
      (appsuiteTokenData != null &&
        urlParams.get(DASHBOARD_TYPE.REDIRECT) != null &&
        urlParams.get(DASHBOARD_TYPE.REDIRECT) ==
          ASSET_REDIRECT_SCOPES.MANAGEMENT_DASHBOARD)
    ) {
      setReady(true);
      navigate(
        "/dashboard/?" +
          DASHBOARD_TYPE.REDIRECT +
          "=" +
          KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD
      );
    } else if (
      tokenData !== null ||
      (appsuiteTokenData != null &&
        urlParams.get(DASHBOARD_TYPE.REDIRECT) != null &&
        urlParams.get(DASHBOARD_TYPE.REDIRECT) ==
          ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD)
    ) {
      setReady(true);
      navigate(
        "/dashboard/?" +
          DASHBOARD_TYPE.REDIRECT +
          "=" +
          ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD
      );
    } else if (
      tokenData !== null ||
      (appsuiteTokenData != null &&
        urlParams.get(DASHBOARD_TYPE.REDIRECT) != null &&
        urlParams.get(DASHBOARD_TYPE.REDIRECT) ==
          ASSET_REDIRECT_SCOPES.MONITORING_DASHBOARD)
    ) {
      setReady(true);
      navigate(
        "/dashboard/?" +
          DASHBOARD_TYPE.REDIRECT +
          "=" +
          ASSET_REDIRECT_SCOPES.MONITORING_DASHBOARD
      );
    } else {
      setReady(true);
      let cookieName = `${process.env.REACT_APP_COOKIE_PREFIX}_user_session_id`;
      cookieUtils.deleteCookie(cookieName);
      navigate("/");
    }

    if (localStorage.getItem(SESSION_KEY.CLEAR_ALL_TOKEN_DATA_KEY) !== null) {
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    document.body.id = theme ? "dark" : "light";
  }, [theme]);

  useEffect(() => {
    if (theme) {
      setLocalThemeState("dark");
    } else {
      setLocalThemeState("light");
    }
  }, [theme]);

  if (!ready) {
    return null;
  }

  return (
    <div className="App" id={localThemeState}>
      <DialogProvider>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </DialogProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {loading ? (
        <div className="loader-row">
          <div className="loader">
            <div id="ld4">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default App;
